<template>
    <div>
        <div class="vx-row email-card">
            <div class="vx-col md:w-1/4 w-full mb-8">
                <div class="vx-card h-full">
                    <span class="email-campaign-img"> <img src="../../assets/images/email-campaigns.svg" alt="" title=""> </span>
                </div>
            </div>
            <div class="vx-col md:w-3/4 w-full mb-8">
                <div class="vx-card h-full">
                    <div class="card-title  pb-0">
                        <h2 class="mb-2">Email Campaigns - Getting Started</h2>
                        <p class="float-left w-full mt-3">Before we get started with email campaigns, we need to collect some default information used for sending email. 
                        You can change this information later, under settings.</p>
                    </div>                    
                </div>
            </div>
            <div class="vx-col w-full">
                <div class="vx-card h-full p-0">                                        
                    <div class="campaign-form-title">
                        <h4>Information</h4>
                    </div>
                    <div class="campaign-form">
                        <form @submit.prevent="checkValidation()" autocomplete="off">
                            <template>
                                <div class="vx-row form-first-step">
                                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full lg:mt-6 mb-8">
                                        <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label=" 'From' First Name" v-model="input" />
                                    </div>
                                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full lg:mt-6 mb-8">
                                        <vs-input class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label=" 'From' Email Address" v-model="input2" />
                                    </div>
                                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full lg:mt-6 mb-8">
                                        <vs-input class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label="Email Subject" v-model="input2" />
                                    </div>
                                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full lg:mt-6 mb-8">
                                        <vs-input class="w-full" icon-pack="feather" icon="icon-phone" icon-no-border label="Phone Number" v-model="input3" />
                                    </div>
                                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full lg:mt-6 mb-8">
                                        <vs-input class="w-full" icon-pack="feather" icon="icon-globe" icon-no-border label="Website" v-model="input4" />
                                    </div>
                                </div>
                            </template>
                            <template>
                                <div class="vx-row form-first-step">
                                    <div class="vx-col md:w-1/2 w-full lg:mt-6 mb-8">
                                        <vs-textarea v-model="saddress" icon-pack="feather" icon="icon-map-pin" icon-no-border placeholder="Street Address" />
                                    </div>
                                    <div class="vx-col md:w-1/2 w-full lg:mt-6 mb-8">
                                        <vs-textarea v-model="addressline" icon-pack="feather" icon="icon-map-pin" icon-no-border  placeholder="Address Line 2" />
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full lg:mt-6 mb-8">
                                        <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" icon-no-border label="Zip Code" v-model="City" />
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full lg:mt-6 mb-8">
                                        <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" icon-no-border label="State" v-model="state" />      
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full lg:mt-6 mb-8">
                                        <label for="" class="vs-input--label">Country</label>
                                        <v-select v-model="country" :options="countryoptions" :reduce="countryoptions => countryoptions.Key" label="Value" :clearable="false" />     
                                    </div>
                                </div>
                            </template>
                        </form>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
export default {
    components: {       
        VxCard,
        vSelect    
    },
    data() {
        return {
            country: "US",
            countryoptions: [],    
        };
    },
};
</script>
